$inosta-green: #b3d45c;

body {
	background-color: #fafafa;
	font-family: roboto;
	color: #222222;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.container {
	margin-top: 60px;
}

.inosta-green {
	color: $inosta-green;
}

div.intro-big {
	display: table;
	margin: 0 auto;

	h3 {
		font-size: 48px;
		font-weight: 100;
		text-shadow: 1px 1px #fff;
		margin: 0 auto;

		em {
			font-style: normal;
		}
	}

	h4 {
		font-size: 24px;
		font-weight: 400;
		margin: auto 0 auto auto;
		margin-top: 0.25em;
		line-height: 1.25em;
		width: 230px;
    	text-align: right;
	}
	
}

.activities {
	text-align: center;
	margin-top: 60px;
	
	.activity-item {
		margin-bottom: 40px;

		.activity-text {
			display: block;
			font-weight: 300;
			font-size: 24px;
		}
	}
}

footer{
	margin-top: 60px;
	text-align: center;
	small{
		color: #999;

	}
}

.mobile {
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
}
.hide-mobile {
	display: none;
	@media (min-width: 769px) {
		display: block;
	}
}

.btn-primary {
	color: $inosta-green;
    border-radius: 0;
    border: 2px solid $inosta-green;
    background-color: transparent;
    font-size: 24px;
    font-weight: 300;

    &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    	background-color: $inosta-green;
    	border: 2px solid $inosta-green;
    	color: #fff;
    	outline: 0;
    	
    }

    &:active {
    	box-shadow: none;
    	background-color: darken($inosta-green, 5);
    }
}

.margin-bottom-20 {
	margin-bottom: 50px;
}

#contact {
	max-width: 900px;
	margin: 0 auto;

	form {
		margin: 0 auto;

		button, input[type=submit] {
			float: right;

			&:after {
				clear: both;
				content: '';
			}
		}
	}
}