#site-header {
	margin-bottom: 60px;
	nav {
		margin-top: 30px;
		
		ul {
			li {
				list-style: none;
				display: inline-block;
				font-size: 18px;
			    font-weight: 300;
			    text-shadow: 1px 1px #fff;
			    margin-right: 20px;

			    &:last-child {
			    	margin-right: 0px;
			    }

			    a {
			    	color: #222;
					text-decoration: none;
			    }
				
				div.active-line {
					opacity: 0;
					width: 100%;
					height: 3px;
					background-color: $inosta-green;
				}

			    &.active, &:hover {
			    	div.active-line {
			    		opacity: 1;
			    	}
			    }
			}
		}

	} 
}